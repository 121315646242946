import {
    Action,
    ThunkAction,
    combineSlices,
    configureStore,
} from '@reduxjs/toolkit';
import { layoutSlice } from './containers/Layout/reducer';
import { loadingSlice } from './containers/Loading/reducer';
import { disconnectedStationsSlice } from './containers/DisconnectedStationsPage/reducer';
import { gatewaysHealthSlice } from './containers/RouterMgmtPage/reducer';
import { slice as locationSlice } from './components/modals/EditLocationModal/slice';
import { slice as editOrgSlice } from './components/modals/EditOrganizationModal/slice';
import { slice as editPanelSlice } from './components/modals/EditPanelModal/slice';
import { slice as editClusterModal } from './components/modals/EditClusterModal/slice';
import { slice as toasterSlice } from './components/Toaster/slice';
import { slice as vehicleSlice } from './components/modals/EditVehicleModal/slice';
import { slice as calloutSlice } from './components/Callout/slice';
import { slice as rfidSlice } from './components/modals/EditRFIDModal/slice';
import { slice as editUserSlice } from './components/modals/EditUserModal/slice';
import { slice as editGatewaySlice } from './components/modals/EditGatewayIPAddressModal/slice';
import { slice as deleteClusterSlice } from './components/modals/DeleteClusterModal/slice';
import { slice as editSessionSlice } from './components/modals/SessionDetailsModal/slice';
import { slice as bulkPropertyDateSlice } from './components/modals/BulkPropertyDateModal/slice';
import { slice as bulkPropertyStatusSlice } from './components/modals/BulkPropertyStatusModal/slice';

// combineSlices allows us to inject slices from VirtualStation after init
export const rootReducer = combineSlices(
    layoutSlice,
    loadingSlice,
    disconnectedStationsSlice,
    gatewaysHealthSlice,
    locationSlice,
    editOrgSlice,
    editPanelSlice,
    editClusterModal,
    toasterSlice,
    vehicleSlice,
    calloutSlice,
    rfidSlice,
    editUserSlice,
    editGatewaySlice,
    deleteClusterSlice,
    editSessionSlice,
    bulkPropertyDateSlice,
    bulkPropertyStatusSlice
);

const store = configureStore({
    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
