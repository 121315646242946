import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import axios from 'axios';
import { createClient } from 'graphql-ws';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import router from '../utils/router';
import store from '../store';
import { addToast } from '../components/Toaster/slice';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
});

const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (
        graphQLErrors?.find(
            (error) => error.extensions?.code === 'UNAUTHENTICATED'
        )
    ) {
        if (window.location.pathname !== '/login')
            router.navigate('/login', { replace: true });
    }
    const internalErrors =
        graphQLErrors?.filter(
            (x) => x.extensions?.code === 'INTERNAL_SERVER_ERROR'
        ) ?? [];
    for (const err of internalErrors) {
        store.dispatch(
            addToast({
                title: `Server Error`,
                message: err.message,
                color: 'red',
            })
        );
    }
    if (networkError) {
        store.dispatch(
            addToast({
                title: `Network Error`,
                message: networkError.message,
                color: 'red',
            })
        );
    }
});

const authLink = setContext(async (_, { headers }) => {
    let token = localStorage.getItem('user-token');
    try {
        if (token) {
            const decoded: JwtPayload = jwtDecode<JwtPayload>(token);
            const refreshToken = localStorage.getItem('refresh-token');
            if (decoded && decoded.exp && refreshToken) {
                if (new Date(decoded.exp * 1000) < new Date()) {
                    // Expired JWT, refresh here
                    try {
                        const result = await axios.post(
                            `${process.env.REACT_APP_AUTH_ENDPOINT}/refresh`,
                            {
                                access_token: token,
                                refresh_token: refreshToken,
                            }
                        );
                        localStorage.setItem(
                            'user-token',
                            result.data.access_token
                        );
                        token = result.data.access_token;
                    } catch {
                        // Refresh token expired

                        if (window.location.pathname !== '/login')
                            router.navigate('/login', { replace: true });
                    }
                }
            }
        } else {
            if (window.location.pathname !== '/login')
                router.navigate('/login', { replace: true });
        }
    } finally {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    }
});

let activeSocket: any;
let timedOut: any;
let connectionLost = false;
const wsLink = new GraphQLWsLink(
    createClient({
        url: `ws${
            process.env.REACT_APP_API_ENDPOINT?.includes('https://') ? 's' : ''
        }://${process.env.REACT_APP_API_ENDPOINT?.replace(
            /https?:\/\//,
            ''
        )}}/`,
        connectionParams: () => {
            let token = localStorage.getItem('user-token');
            return {
                Authorization: token ? `Bearer ${token}` : '',
            };
        },
        shouldRetry: () => true,
        keepAlive: 10_000, // ping server every 10 seconds
        retryAttempts: 60,
        // specify our own strategy for re-connecting to server, every 5 secs is the same as the mobile app
        retryWait: async function everyFiveSeconds(retries) {
            let retryDelay = 5000;
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
        },
        on: {
            connected: (socket) => {
                if (connectionLost) {
                    store.dispatch(
                        addToast({
                            title: `Network Status`,
                            message: 'Successfully reconnected to the server',
                            color: 'green',
                        })
                    );
                    connectionLost = false;
                }
                activeSocket = socket;
            },
            ping: (received) => {
                if (!received)
                    // sent
                    timedOut = setTimeout(() => {
                        if (activeSocket.readyState === WebSocket.OPEN)
                            activeSocket.close(4408, 'Request Timeout');
                    }, 5_000); // wait 5 seconds for the pong and then close the connection
            },
            pong: (received) => {
                if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
            },
            closed: () => {
                if (!connectionLost) {
                    store.dispatch(
                        addToast({
                            title: `Network Status`,
                            message:
                                'Connection to server lost, trying to reconnect automatically',
                            color: 'red',
                        })
                    );
                    connectionLost = true;
                }
            },
        },
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    // different Queries hit the same 'station' field (GetStation, GetStationLogs, GetStationPricingHistory,...)
    // those clash and prevent Apollo from caching the response, this solves that issue
    // https://www.apollographql.com/docs/react/caching/advanced-topics/
    // PS also added the same for 'user'
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    station: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Station',
                                id: args?.filter.id,
                            });
                        },
                    },
                    user: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'User',
                                id: args?.filter.id,
                            });
                        },
                    },
                },
            },
        },
    }),
    link: authLink.concat(logoutLink).concat(splitLink),
});

export default client;
