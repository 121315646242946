import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface PropertyStatusModalState {
    isOpen: boolean;
    propertyId: string;
}

const initialState: PropertyStatusModalState = {
    isOpen: false,
    propertyId: '',
};

export const slice = createSlice({
    name: 'propertyStatusModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.propertyId = action.payload;
        },
    },
});

export const { setOpen, setPropertyId } = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) =>
    state.propertyStatusModal.isOpen;
export const selectPropertyId = (state: RootState) =>
    state.propertyStatusModal.propertyId;
