import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Organization from '../../../models/Organization';

export interface OrganizationModalState {
    isOpen: boolean;
    initialOrganization?: Organization | null;
}

const initialState: OrganizationModalState = {
    isOpen: false,
};

export const slice = createSlice({
    name: 'organizationModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setInitialOrganization: (
            state,
            action: PayloadAction<Organization | null | undefined>
        ) => {
            state.initialOrganization = action.payload;
        },
    },
});

export const { setOpen, setInitialOrganization } = slice.actions;
export const actions = slice.actions;

export const selectIsOpen = (state: RootState) =>
    state.organizationModal.isOpen;
export const selectInitialOrganization = (state: RootState) =>
    state.organizationModal.initialOrganization;

export default slice.reducer;
